<template>
  <a-row>
    <a-row class="content-title" type="flex">
      <h2>{{ $t("UserTitle") }}</h2>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="$router.push({ name: 'user-create' })"
      >
        <a-icon type="plus" /> {{ $t("Add") }}
      </a-button>
    </a-row>

    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="{ defaultPageSize: 5 }"
      :scroll="{ x: 1600, y: 600 }"
      :row-key="(record) => record.id"
    >
      <template slot="id" slot-scope="item">
        <!--<a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />-->
        <span
          class="td-post-item"
          @click="
            $router.push({ name: 'user-detail', params: { id: item.id } })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="first_name" slot-scope="item">
        <span>
          {{ item.first_name || "-" }}
        </span>
      </template>

      <template slot="last_name" slot-scope="item">
        <span>
          {{ item.last_name || "-" }}
        </span>
      </template>

      <template slot="middle_name" slot-scope="item">
        <span>
          {{ item.middle_name || "-" }}
        </span>
      </template>

      <template slot="birth_date" slot-scope="item">
        <span>
          {{ moment(item.date).format("LL") || "-" }}
        </span>
      </template>

      <template slot="gender" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-tag :color="item.gender === 1 ? 'geekblue' : 'volcano'">
          {{ item.gender === 1 ? $t("FormMale") : $t("FormFemale") }}
        </a-tag>
      </template>

      <template slot="phone" slot-scope="item">
        <span>
          {{ item.phone || "-" }}
        </span>
      </template>

      <template slot="profession" slot-scope="item">
        <span>
          {{ item.profession || "-" }}
        </span>
      </template>

      <template slot="role" slot-scope="item">
        <span>
          {{ item.role || "-" }}
        </span>
      </template>

      <template slot="username" slot-scope="item">
        <span
          class="td-post-item"
          @click="
            $router.push({ name: 'user-detail', params: { id: item.id } })
          "
        >
          {{ item.username }}
        </span>
      </template>

      <template slot="status" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? "Активный" : "Неактивный" }}
        </a-tag>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.date_joined).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push({ name: 'user-detail', params: { id: item.id } })
          "
        >
          <a-icon class="action-btns" type="edit" />
          <!--{{ $t("Edit") }}-->
        </a-button>
        <a-popconfirm
          cancel-text="Нет"
          ok-text="Да"
          title="Вы действительно хотите удалить?"
          @confirm="removeUser(item)"
        >
          <a-button type="danger">
            <a-icon class="action-btns" type="delete" />
            <!--{{ $t("Delete") }}-->
          </a-button>
          <!--<a-icon class="action-btns" style="margin: 0 10px" type="delete" />-->
        </a-popconfirm>
      </template>
    </a-table>
    <!-- <pre>{{list}}</pre> -->
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      list: [],
      columns: [
        {
          title: "ID",
          key: "id",
          width: "80px",
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("FormFirstName"),
          // dataIndex: "username",
          key: "first_name",
          width: "10%",
          scopedSlots: { customRender: "first_name" }
        },
        {
          title: this.$t("FormLastName"),
          key: "last_name",
          width: "10%",
          scopedSlots: { customRender: "last_name" }
        },
        {
          title: this.$t("FormMiddleName"),
          key: "middle_name",
          width: "10%",
          scopedSlots: { customRender: "middle_name" }
        },
        {
          title: this.$t("FormBirthDate"),
          key: "birth_date",
          width: "15%",
          scopedSlots: { customRender: "birth_date" }
        },
        {
          title: this.$t("FormGender"),
          key: "gender",
          width: "7%",
          scopedSlots: { customRender: "gender" }
        },
        {
          title: this.$t("FormPhone"),
          key: "phone",
          width: "15%",
          scopedSlots: { customRender: "phone" }
        },
        {
          title: this.$t("FormProfession"),
          key: "profession",
          width: "10%",
          scopedSlots: { customRender: "profession" }
        },
        {
          title: this.$t("FormRole"),
          key: "role",
          width: "7%",
          scopedSlots: { customRender: "role" }
        },
        {
          title: this.$t("UsersColTitle"),
          key: "username",
          width: "10%",
          scopedSlots: { customRender: "username" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: "7%",
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "20%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ]
    }
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      this.loading = true
      // try {
      const { data } = (await this.$store.dispatch("user/fetchList")) || []
      if (data) {
        this.list = data
      }
      this.loading = false
    },
    async removeUser(arg) {
      try {
        const r = await this.$api.delete("/admin/user/" + arg.id + "/delete/")

        if (r && r.status >= 200 && r.status < 300) {
          const f = this.list.findIndex((item) => item.id === arg.id)

          if (f !== -1) {
            this.list.splice(f, 1)
          }
        }
        // console.log(r)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
